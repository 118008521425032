































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CustomerProjectList, { ICustomerProjectList_Item } from '../modules/CustomerProject/List';

@Component
export default class CustomerProjectSelect extends Vue {
  @Prop() readonly value?:ICustomerProjectList_Item|null
  @Prop(Boolean) readonly disabled?:boolean
  @Prop(Number) readonly customerId?:number

  isBusy = false;

  searchQuery = '';

  perPage = 10;
  currentPage = 1;

  customerProjects = new CustomerProjectList();
  fields = [
    { key: 'ProjectNumber',  label: this.$t('Project number')                            },
    { key: 'ProjectName',    label: this.$t('Project name'), class: 'display-priority-2' },
    { key: 'Address',        label: this.$t('Address'),      class: 'display-priority-5' },
    { key: 'Address2',       label: this.$t('Address 2'),    class: 'display-priority-5' },
    { key: 'PostalCode',     label: this.$t('Postal code'),  class: 'display-priority-2' },
    { key: 'PostalArea',     label: this.$t('Postal area'),  class: 'display-priority-1' },
  ];

  async fetchCustomerProjects() {
    if (!this.customerId)
      return;

    this.isBusy = true;
    this.customerProjects = await CustomerProjectList.Fetch(this.customerId, this.currentPage, this.perPage, this.searchQuery);
    this.isBusy = false;
  }

  resetPageAndReloadCustomerProjects() {
    this.pageSelected(1);
  }

  pageSelected(page: number) {
    this.currentPage = page;
    this.fetchCustomerProjects();
  }

  customerProjectSelected(record: ICustomerProjectList_Item, idx: number) {
    (this as any).$bvModal.hide('customer-project-select-modal');
    this.$emit('input', record);
  }
}
